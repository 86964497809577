
.piano {
  display: inline-block;
  padding: 10px;
  width: 100%;
-webkit-touch-callout:none;
-webkit-user-select:none;
-khtml-user-select:none;
-moz-user-select:none;
-ms-user-select:none;
user-select:none;
-webkit-tap-highlight-color:rgba(0,0,0,0);
}

.effects{
-webkit-touch-callout:none;
-webkit-user-select:none;
-khtml-user-select:none;
-moz-user-select:none;
-ms-user-select:none;
user-select:none;
-webkit-tap-highlight-color:rgba(0,0,0,0);
}

.PianoDarkTheme .ReactPiano__Key--accidental {
    background: #010507;
    border: 1px solid #888;
  }
  .PianoDarkTheme .ReactPiano__Key--natural {
    background: #350142;
    border: 1px solid rgb(247, 126, 201);
    margin-right: 0;
  }
  .PianoDarkTheme .ReactPiano__Key--active.ReactPiano__Key--accidental {
    background: #f717f7;
  }
  .PianoDarkTheme .ReactPiano__Key--active.ReactPiano__Key--natural {
    background: #9203ca;
  }

  .ReactPiano__NoteLabelContainer {
    visibility: hidden;
  }